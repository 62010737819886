// import { Link } from "@StarberryUtils";
import React from "react";
import {Col, Row, Container } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
// import VideoCard from '../VideoCard/VideoCard';
import  "../MgFavourites/MgFavourites.scss";
// import favImg01 from "../../../images/fav-img01.jpg"
// import favImg02 from "../../../images/fav-img02.jpg"
// import favImg03 from "../../../images/fav-img03.jpg"
// import favImg04 from "../../../images/fav-img04.jpg"
import GenerateLink from "../../common/site/generate-link";

import { inViewOptions } from '../../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}
const titleVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.2,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.8
    }
  }
}
const itemsVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    }
  }
}
const item = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
}
const MotionRow = motion(Row)
const MotionColumn = motion(Col)

const InstructMG=(props)=>{

  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="mg-favourites reason-to-instruct"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <Container>
            <Row>
              <Col md={12} className="head">
                <motion.div variants={titleVariants}>
                  <ReactMarkdown source={props.Content ? props.Content : ''} allowDangerousHtml />
                </motion.div>
              </Col>
            </Row> 
            <MotionRow variants={itemsVariants}>

            {props.Reasons.map((reason, i) => {
              return (
                <MotionColumn key={i} xl={6} className="instruct-grid" variants={item}>
                
                    <div className="favourite-item d-flex">
                        {reason.Image ? <figure>
                            <img className="img" loading="lazy" src={reason.Image.childImageSharp.gatsbyImageData.images.fallback.src} alt={`${reason.Title} - Martyn Gerrard`} />
                        </figure> : '' }
                        <div className="info">
                            {reason.Link ? <GenerateLink link={reason.Link} class="info-title" label={reason.Title}>{reason.Title}</GenerateLink> : <p className="info-title">{reason.Title}</p>}
                            
                            <ReactMarkdown source={reason.Content ? reason.Content : ''} allowDangerousHtml />
                        </div>
                    </div>
                    
                </MotionColumn>
              );
            })}

            </MotionRow>       
          </Container>
        </motion.section>
      )}
    </InView>
  );
}

export default InstructMG
