import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Slider from 'react-slick';
import ReactMarkdown from "react-markdown"
import {Col, Row, Container } from 'react-bootstrap';
import  "../started/started.scss";
import deviceImgTab from "../../../images/mob-device-tab.png"
import navLogoWhite from "../../../images/my-mg-logo-white.svg";
import { getSrc } from "gatsby-plugin-image"
import { getToken } from '../../../services/store/utils'
// import { GetURL } from "../../common/site/functions";

// import { motion } from "framer-motion";
// import { InView } from "react-intersection-observer";

const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}

const imageVariants = {
  hidden: {
    opacity: 0,
    scale: 0.5
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}

const titleVariants = {
  hidden: {
    opacity: 0,
    y: 40 
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}

const Started =(props) =>{
    
    const settings = {
      dots: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      arrows: false,
      autoplay:true
    }
    


  const data = useStaticQuery(graphql`
    query{
      strapiGlobalModules {
        MG_Get_Started {
          Image {
            childImageSharp {
              gatsbyImageData(formats: WEBP
                width: 500
                quality: 80
                layout: FIXED
                transformOptions: {cropFocus: CENTER, fit: COVER}
              )
            }
          }
          Add_Slide {
            Content
            CTA_Label
            CTA_Link {
              id
            }
          }
        }
      }
    }
  `);
  var loginChk = false;
  if(getToken())
    loginChk = true;

  const myaccountURL = process.env.GATSBY_MYACCOUNT_URL;
  const getStartedURL = process.env.GATSBY_MYACCOUNT_URL+'/../signup';

    return(
        <>
          <section className="get-started components-Home-started-started">
            <Container>
              <Row className="align-items-center"> 
                <Col md={4} lg={5}>
                  <figure>
                    <picture>
                      <source media="(min-width:768px) and (max-width:991px)" srcset={deviceImgTab} />
                      <img loading="lazy" variants={imageVariants} className="banner-img" src={getSrc(data.strapiGlobalModules.MG_Get_Started.Image)} alt={`${props.page} Get started - Martyn Gerrard`}></img>
                    </picture>
                  </figure>
                </Col>
                <Col md={8} lg={7}>
                <div variants={titleVariants}>
                  <Slider className="started-slider"  {...settings} >

                    {data.strapiGlobalModules.MG_Get_Started.Add_Slide.map((slide, i) => {
                      // let url = GetURL(slide.CTA_Link.id)
                      return (
                        <div key={i} className={i === 0 ? "started-details text-center text-md-left textContact animate__animated" : "started-details text-center text-md-left"}>
                          <span className="my-logo"><img loading="lazy" src={navLogoWhite} alt="nav-logo" /></span>
                          <ReactMarkdown source={slide.Content ? slide.Content : ''} allowDangerousHtml />
                            {!loginChk && slide.CTA_Label &&
                              <a href={getStartedURL} role="button" className="btn btn-default mr-lg-4 mb-4">{slide.CTA_Label}</a>
                            }
                            {!loginChk && 'My MG' == props.page &&
                              <a href={myaccountURL} role="button" className="btn btn-default mb-4">Log in</a>
                            }
                            {loginChk && slide.CTA_Label &&
                              <a href={myaccountURL} role="button" className="btn btn-default mr-lg-4 mb-4">My account</a>
                            }
                        </div>
                      )
                    })}

                  </Slider>
                </div>
                </Col>
              </Row>
            </Container>
          </section>
        </>
    )
}
  

export default Started
