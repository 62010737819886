import { Link } from "@StarberryUtils";
import { useStaticQuery, graphql } from "gatsby";
import React,{ useState } from "react";
import Slider from 'react-slick';
import {Col, Row, Container } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
import  "../Reviews/Reviews.scss";
import googleReview from "../../../images/google reviews logo.svg";
import video6 from "../../../images/video-img08.jpg";
import Video from "../../Play/custom-video";

import Score from '../../Static/reviewScoreStatic'

import { GetURL } from "../../common/site/functions";

// import { motion } from "framer-motion";
// import { InView } from "react-intersection-observer";

const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}

const titleVariants = {
  hidden: {
    opacity: 0,
    y: 40
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.8
    }
  }
}

const contentVariants = {
  hidden: {
      opacity: 0
  },
  visible: {
      opacity: 1,
      transition: {
          staggerChildren: 0.4
      }
  }
}

const contentItem = {
  hidden: { opacity: 0, y: 30 },
  visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: {
          delay: custom * 0.2,
          duration: 0.4,
          ease: [0.33, 1, 0.68, 1],
      }
  })
}

const Reviews =()=> {

  const settings = {
    dots: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    mobileFirst:true,
    
    responsive: [
      {
          breakpoint: 9999,
          settings: "unslick"
      },
      {
          breakpoint: 767,
           settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
              }
      }
  ]
  }


  const data = useStaticQuery(graphql`{
  strapiGlobalModules {
    Reviews {
      Content
      CTA_Label
      CTA_Link {
        id
      }
      Show_Multiple_Reviews
      Video_URL
      Video_Poster_Img {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 736, layout: CONSTRAINED)
        }
      }
      Choose_Reviews_to_show {
        Name
        Image {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 250, layout: CONSTRAINED)
          }
        }
        Embed_Video_URL
      }
    }
  }
}
`);


  let url = GetURL(data.strapiGlobalModules.Reviews.CTA_Link.id)
  return (
    <>
        <section
          className="customers-reviews components-Home-Reviews-Reviews"
        >
          <Container>
            <Row className="align-items-xl-center">
              <Col lg={4}>
                <div variants={titleVariants}>
                  <span className="google-review"><img loading="lazy" src={googleReview} alt="googleReview" /></span>
                  <Score />
                  <ReactMarkdown source={data.strapiGlobalModules.Reviews.Content ? data.strapiGlobalModules.Reviews.Content : ''} allowDangerousHtml />
                  {data.strapiGlobalModules.Reviews.CTA_Label ?
                  <Link className="btn btn-success d-block d-md-inline-block" to={`/${url}`}>{data.strapiGlobalModules.Reviews.CTA_Label}</Link> : '' }
                </div>
              </Col>
              <Col lg={8}>
                {data.strapiGlobalModules?.Reviews?.Show_Multiple_Reviews ? 
                <div variants={contentVariants}>
                  <Slider className="reviews-slider"  {...settings} >
                    {data.strapiGlobalModules.Reviews.Choose_Reviews_to_show.map((review, i) => {
                      return (
                        <React.Fragment key={i}>
                          <div className="review-video ">
                            <div
                              custom={i + 1}
                              className="land"
                              variants={contentItem}
                            >
                              <img loading="lazy" src={review?.Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src} alt={`${review.Name} - Martyn Gerrard`} />
                              <Video title={`${review.Name} Review`} url={review.Embed_Video_URL}/>
                            </div>
                            <p>{review.Name}</p>
                          </div>

                          {i === 4 ?
                            <div className="review-video d-md-none d-1800-block d-none">
                              <div
                                custom={i}
                                variants={contentItem}
                              >
                                <img loading="lazy" src={review?.Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src} alt={`${review.Name} - Martyn Gerrard`} />
                                <Video title={`${review.Name} Review`} url={review.Video_URL}/>
                              </div>
                            <p>{review.Name}</p>
                            </div>
                            : ''}

                          </React.Fragment>
                      );
                    })}
                  </Slider>
                </div>
                :
                <div className="single-video-reviews">
                  <React.Fragment>
                    <div className="review-video ">
                        <div className="land" variants={contentItem} >
                            <img loading="lazy" src={data.strapiGlobalModules?.Reviews?.Video_Poster_Img?.childImageSharp?.gatsbyImageData?.images?.fallback?.src} alt={`Reviews - Martyn Gerrard`} />
                            <Video title={`Review`} url={data.strapiGlobalModules?.Reviews?.Video_URL}/>
                        </div>
                    </div>
                  </React.Fragment>
                </div>
                }
              </Col>
            </Row>
          </Container>
        </section>
      </>
  );
  }

export default Reviews
