import React from "react";
import { Link } from "@StarberryUtils";

import { GetURL } from "./functions";


const GenerateLink =(props,{children})=> {
	let url = ''
	let link = ''
	if (props.link) {
		url = GetURL(props.link.id)
	}

	if(url.indexOf("myaccount") > -1) {
		link = "myaccount"
	}
    return(
		<>
		{link == "myaccount" ?
    	<a href={`${url}`} className={props.class}>{props.children}</a>
		:
    	<Link to={`/${url}`} className={props.class}>{props.children}</Link>
		}
		</>
    )
}

export default GenerateLink;
