// import { Link } from "@StarberryUtils";
// import PropTypes from "prop-types";
import React from "react";
import {Col, Row, Container } from 'react-bootstrap';
// import VideoCard from '../VideoCard/VideoCard';
import  "./OfficeNumbers.scss";
// import profImg3 from "../../../images/profile-img03.png"
import Counter from '../Counter/number-counter';
import ReactMarkdown from "react-markdown"

import { inViewOptions } from '../../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 60,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}

  const OfficeDetailsNumbers=(props)=>{

  return(
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="office-numbers data office-details-stats"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <Container>
          {props.intro ?
            <Row>
              <Col md={12}>
                <ReactMarkdown source={props.intro ? props.intro : ''} allowDangerousHtml />
              </Col>
            </Row> : '' }

            <Row>
              {props.data.Add_Stats.map((item, i) => {
              let sym = item.Count[item.Count.length -1]
              let symbol = ''
              if(isNaN(sym))
              {
                symbol = sym
              }
              else
              {
                symbol = ''
              }
              let decimal = 0
              if(item.Count.indexOf('.') !== -1) {
                decimal = 1
              }
              let start = ''
              if(item.Count.indexOf('£') !== -1) {
                start = "yes"
              }
                return (
                  <Col md={6} xl={3}>
                      <strong className="numbers">
                      {start == "yes" ? '£' : ''}
                      <Counter
                            start={item.Count.replace(/[£,%m]/g, '')} // {item.Count.replace("%",'').replace(",",'').replace("£",'').replace("m",'')}
                            sym={symbol}
                            duration={5}
                            />
                            </strong>
                      <ReactMarkdown source={item.Content ? item.Content : ''} allowDangerousHtml />
                  </Col>
                )
              })}
            </Row>
          </Container>
        </motion.section>
      )}
    </InView>
    )
  }

export default OfficeDetailsNumbers
