import { Link } from "@StarberryUtils";
import PropTypes from "prop-types";
import React,{useEffect, useState} from "react";
import {Col, Row, Container } from 'react-bootstrap';
import VideoCard from '../VideoCard/VideoCard';
import  "./EastSchedule.scss";
import profImg1 from "../../../images/profile-img01.png"
import profImg2 from "../../../images/profile-img02.png"
import eastMain from "../../../images/east-main-video1.jpg";
import eastMain2 from "../../../images/east-main-video3.jpg";
import eastMain3 from "../../../images/east-main-video4.jpg";
import VideoPlay from "../../Play/custom-video";
import { Carousel } from 'react-responsive-carousel';
import ReactMarkdown from "react-markdown"
import "../VideoCard/VideoCard.scss"

import { MEET_THE_TEAM_PAGE_URL, CONTACT_PAGE_URL } from "../../common/site/constants";
import Breadcrumbs from "../../MgServices/Breadcrumb/news-breadcrumb"
import SocialShare from "../../utils/socialShare";

const EastSchedule=(props)=>{

    const [isPlay,setPlay] = useState(false);
    const [isPlaying,setPlaying] = useState(false);
    const [isPlayn,setPlayn] = useState(false);

    var page_url = CONTACT_PAGE_URL.alias.split( '/' )

  const [Shareicons,setShareicons] = React.useState(false);
  const openShareicons = () => {
    setShareicons(true);
    if (Shareicons === true) {
      setShareicons(false);
    }
  }

  const closeShareicons = () => {
    setShareicons(false);
  }

  return (
    <section className="east-schedule">
      <Container>

        <Row>
          <Col xl={6}>

          <Breadcrumbs url={page_url.pop()} tag="contact" Title={props.data.Name} class="office-details-breadcrumb"/>

          <h1 className="sec-title">Estate agents in {props.data.Name}</h1>
          <a className="contact-no" href={`tel:${props.data.Phone}`}>{props.data.Phone}</a>
          <p className="add-txt">{props.data.Address}</p>
          {props.data.Opening_Hours ?
          <div className="time-schedule">
              <strong className="opening-txt">Opening hours: </strong>
              <ReactMarkdown source={props.data.Opening_Hours} allowDangerousHtml />
          </div> : '' }
          <div className="btns-wrp d-md-flex align-items-center">
              <Link 
                className="btn" 
                to={`/market-your-property/book-a-valuation/`}
                state={{ 
                  office: props.data.Name,
                  office_email: props.data.Email
                }}
              >
                  book a valuation
              </Link>
              <Link className="btn btn-success" to="reach-us">
                  contact office
              </Link>
          </div>
          <div className="share-print">
            <a href="javascript:void(0)" onClick={openShareicons} onMouseEnter={openShareicons} onMouseLeave={closeShareicons}><i className="icon-share"></i>Share
              {Shareicons &&
                <SocialShare openShareicons={openShareicons} />
              }
            </a>
              {/* <Link to="#"><i className="icon-print"></i>Print</Link> */}
          </div>
          {props.data.Peoples_to_Contact.length !== 0 ?
          <div className="profile-wrap d-md-flex">
              {props.data.Peoples_to_Contact.map((people, i) => {
                return (
                  <div className="profile-item d-flex align-items-center">
                    {people.Tile_Image &&
                      <span className="profile-img">
                          <img loading="lazy" src={people.Tile_Image.publicURL} alt={`${people.Name} - Martyn Gerrard`} />
                      </span>
                    }
                      <div className="info">
                          <strong className="pro-name">{people.Name}</strong>
                          <p className="pro-post">{people.Designation}</p>
                          <div className="bio-contact">
                              <a href={`tel:${people.Phone}`}>Contact </a><Link to={`${MEET_THE_TEAM_PAGE_URL.alias}/${people.URL}`}>Bio</Link>
                          </div>
                      </div>
                  </div>
                )
              })}
          </div> : '' }
          </Col>
          <Col xl={6}>
          <div class="east-right-video">
              {props.data.Office_Images_Videos.slice(0,1).map((video, i) => {
              let video_url = ''
              if(video.Embed_Video_URL != null)
              {
                video_url = video.Embed_Video_URL
              }
              else if(video.Upload_Video != null) {
                video_url = video.Upload_Video.url
              }
                return (
                  <div className="main-video">
                    {video.Image &&
                    <div className="video-card ">
                        <figure className="video-img">
                            <img loading="lazy" src={video.Image.childImageSharp.gatsbyImageData.images.fallback.src} alt={`${video.Title ? video.Title : props.data.Name} - Martyn Gerrard`} />
                            {video_url ? <VideoPlay title={props.data.Name} url={video_url}/> : '' }
                          </figure>
                      </div>
                    }
                  </div>
                );
              })}

              <div className="child-video d-flex justify-content-between">
              {props.data.Office_Images_Videos.slice(1,3).map((video, i) => {
              let video_url = ''
              if(video.Embed_Video_URL != null)
              {
                video_url = video.Embed_Video_URL
              }
              else if(video.Upload_Video != null) {
                video_url = video.Upload_Video.url
              }
                return (
                  <div className="video-card ">
                  {video.Image &&
                    <figure className="video-img">
                      <img loading="lazy" src={video.Image.childImageSharp.gatsbyImageData.images.fallback.src} alt={`${video.Title ? video.Title : props.data.Name} - Martyn Gerrard`} />
                      {video_url ? <VideoPlay url={video_url}/> : '' }
                    </figure>
                  }
                  <p className="schedule">{video.Title}</p>
                </div>
                );
              })}

              </div>
              </div>
            <div class="east-right-video-mobile">
                  <Carousel className="main-video" autoPlay={1} interval={3000} infiniteLoop={1}>

          {props.data.Office_Images_Videos.map((image, i) => {
              let video_url = ''
              if(image.Embed_Video_URL != null)
              {
                video_url = image.Embed_Video_URL
              }
              else if(image.Upload_Video != null) {
                video_url = image.Upload_Video.url
              }
            return (
              <figure>
                  {image.Image ? <img loading="lazy" src={image.Image.childImageSharp.gatsbyImageData.images.fallback.src} alt={`${props.data.Name ? props.data.Name : ''} - Martyn Gerrard`} /> : '' }
                  {image.Image_Label ? <span className="tag-abs text-uppercase">{image.Image_Label}</span> : '' }
                  {image.Embed_Video_URL ? <VideoPlay title={props.data.Name} url={image.Embed_Video_URL}/> : '' }
              </figure>
            );
          })}
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>


    <div className="fixed-btns d-flex d-xl-none d-lg-flex office-details-sticky-nav">
      <Link to="/market-your-property/book-a-valuation/" className="btn ask-btn btn-success d-md-block">Book a valuation</Link>
      <Link to="reach-us" className="btn book-btn d-md-block">contact office</Link>
    </div>

    </section>
  );
  }

export default EastSchedule
