import { Link } from "@StarberryUtils";
import PropTypes from "prop-types";
import React,{useState, useEffect} from "react";
import {Col, Row, Container } from 'react-bootstrap';
import Slider from 'react-slick';
import  "../Static/Intro/Intro.scss";
//import "animate.css/animate.min.css";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import VideoPlay from "../Play/custom-video";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import ReactMarkdown from "react-markdown"

import { MEET_THE_TEAM_PAGE_URL } from "../common/site/constants";


const GET_OFFICE = gql`
  query GetOffice($URL: String!){   
    offices(where:{URL:$URL}) {
      Name
      Members {
        Name
        Embed_Video_URL
        URL
        Upload_Video {
          url
        }
        Tile_Image {
          url
        }
        imagetransforms
        id
        Designation
        Phone
      }
    }
  }
`;


    const settings =  {
        dots: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        autoplay: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true
            }
          }
        ]
      }

function OfficeDetailsTeam(props) {
  
  const { loading, error, data } = useQuery(GET_OFFICE, {
    variables: { URL: props.url }
  });

    if (loading) return <p>Loading ...</p>;

return(
  <section className="managing-sec managing-intro people-listing similar-sec">

    <div className="review-testimoials office-details-team-slider">
      <Container>
        <h2>{props.Title} Office Team</h2>
        {props.intro && 
          <div className="office-detail-team-intro">
          <ReactMarkdown source={props.intro} allowDangerousHtml />
          </div>
        }
        {data.offices.map((offices, i) => {
          return ( 
          <Slider className="property-slider"  {...settings} >
          {offices.Members.map((member, index) => {
            let video_url = ''
            if(member.Embed_Video_URL != null)
            {
              video_url = member.Embed_Video_URL
            }
            else if(member.Upload_Video != null) {
              video_url = member.Upload_Video.url
            }


          const image_url = member.Tile_Image.url

          let processedImages = JSON.stringify({});
          if (member?.imagetransforms?.Tile_Image_Transforms) {
              processedImages = member.imagetransforms.Tile_Image_Transforms;
          }

            return (
              <div className="slide-item" key={index}>
                <Link to={`${MEET_THE_TEAM_PAGE_URL.alias}/${member.URL}`}>
                <div className="video-card list-tile office-details-page-team-list">
                    <figure className="video-img">

                    <ImageTransform imagesources={image_url} renderer="srcSet" imagename="people.Tile_Image.tile" attr={{ alt: props.Name + ' - Martyn Gerrard', class:'' }} imagetransformresult={processedImages} id={member.id}/>

                        {video_url ? <VideoPlay url={video_url}/> : '' }
                      </figure>
                    <strong className="video-title d-block">{member.Name}</strong>
                    <p className="schedule">{member.Designation}</p>
                    <span className="contact-no">{member.Phone}</span>
                    <strong className="video-title d-none">{props.videoTitle}</strong>
                    <p className="schedule d-none">{props.schedule}</p>
                    <span className="contact-no d-none">{props.contactNo}</span>
                    
                  </div>
                  </Link>
              </div>
            )
          })}
        </Slider>
          )
        })}
      </Container>
    </div>
  
  </section>
)
}


export default OfficeDetailsTeam
